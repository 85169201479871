var links = document.querySelectorAll('#cards-team a');


		
		links.forEach(function(link) {
				link.addEventListener('click', function(e) {
						
					
						
					

						
						var target = e.target;
						while (target && target.nodeName !== 'A') {
								target = target.parentNode
						}

						
						if (target) {
							
								var classes = target.className.split(' ')

								
								var ordemClass = classes.find(function(c) {
										return c.startsWith('ordem-')
								})

								
								if (ordemClass) {
										
										var pessoaId = ordemClass.split('-')[1]

									
										minhaFuncao(pessoaId)

										
										

								}
						}
				});
		});


		function minhaFuncao(id) {
			//console.log(id)
			localStorage.setItem('pessoaId', id);
		}

		

export default minhaFuncao
