import axios from 'axios';
import axiosConfig from './modules/axiosConfig';
import PageHome from './pages/Home.js';
import PageMenbro from './pages/membro.js';
import PageLogin from "./pages/Login";
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import mottaEquipeText from "./modules/converteditorjs";

import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import animateFlows from "./animation/animateFlows";

import Swiper from 'swiper';
import 'swiper/css';

import menu from "./flows/menu.js"
import contact from "./flows/contact.js"

if (window.matchMedia('(max-width: 1279px)')) {
	const swiper = new Swiper('#swiper-partners', {
		slidesPerView: 1.5,
		autoplay: true,
		spaceBetween: 16,

		pagination: {
			el: '.swiper-pagination',
		},

		breakpoints: {
			1024: {

			},
			1280: {
				autoplay: false,
				spaceBetween: 0,
				slidesPerView: 'auto',
			}
		}
	});
}

// import SvgUse from "./utils/SvgUse";

// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()

// document.addEventListener("DOMContentLoaded", () => {

// 	document.body.classList.add("opc");

// Animations
animateFlows()
animateSetup()
animaAoScroll()
mottaEquipeText()


// Pages
PageHome()
PageLogin()?.init()
PageMenbro()

// Menu mobile
menu()
contact()

// utils logs
logs()

// })



