import { find } from "utils/dom"
import minhaFuncao from "../modules/linkId.js"

export default function () {
	const Menbro = find("[js-page='membroEquipe']")
	if (!Menbro) return

	
	var pessoaId = localStorage.getItem('pessoaId');
	console.log(pessoaId)
	minhaFuncao(pessoaId)

	

	





	
		
		


	
}